.sidebar {
    width: 220px;
    background-color: #fff;
    z-index: 1000;
    left: -110px;
    position: fixed;
    bottom: 0;
    top: 59px;
    right: auto;
    padding-right: 2px;
    -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99), -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    -webkit-backface-visibility: hidden;
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
    transform: translate(110px, 0px);
}

.menu {
    height: calc(100% - 50px);
    position: relative;
    width: 100%;
    overflow-y: hidden;
    margin-top: 20px;
}

.menu::-webkit-scrollbar {
    width: 4px;
    min-height: 24px;
    margin-right: 2px;
    right: 2px;
}
.menu::-webkit-scrollbar-thumb {
    background-color: #d2d6db;
    margin-right: 2px;
}
.menu::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
}
.menu > ul {
    list-style-type: none;
    padding-left: 0;
}

.menu ul li {
    margin-bottom: 7px;
}
.menu ul li:hover > a {
    text-decoration: none;
}
.menu ul li:hover > a > span {
    color: #000;
}
.menu ul li:hover > span {
    color: #000;
}
.menu a {
    text-decoration: none;
    font-family: Arial, sans-serif;
    display: inline-block;
    padding-left: 22px;
    min-height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #627182;
    width: 65%;
}
.title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 65%;
}
.icon-thumbnail {
    width: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    float: right;
    margin-right: 12px;
    color: #627182;
}
.open .icon-thumbnail {
    margin-right: 22px;
}
.active > a > span {
    color: #000;
}
.active > span {
    color: #000;
}
