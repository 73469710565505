.lable {
    line-height: 1em;
    margin-bottom: 0;
}

.form-checkbox {
    max-width: 290px;
    line-height: 1em;
}

.submit-button {
    background-color: #ffffff;
    color: rgba(0, 0, 255, 1);
    border-width: 1px;
    border-color: rgba(0, 0, 255, 1);
    font-size: 13px;
    line-height: 14px;
    padding: 15px 20px;
    font-weight: 700 !important;
    font-style: normal;
    font-family: Nunito-Bold;
    text-transform: uppercase;
}

.form-check-input {
    border: 1px solid #000;
}
