.mobility-coach {
    background-color: #fff;
    min-height: 80vh;
    padding: 20px 0;
}

.mobility-coach .page-headline {
    font-family: Nunito-Bold, sans-serif;
    font-size: 24px;
}

.mobility-coach .text-highlighted {
    color: #0004F5;
}

.mobility-coach .section-info {
    padding: 40px 0;
    background-color: #EFEFEF;
    margin-bottom: 20px;
    font-family: Nunito-Bold, sans-serif;
}

.mobility-coach .section-detailed {
    padding: 0 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobility-coach .section-detailed button {
    margin-bottom: 15px;
}
