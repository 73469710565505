.cv-tabs .nav-item {
    margin-bottom: 20px;
}

.cv-tabs .nav-item button {
    border: 1px solid var(--bs-primary) !important;
    border-radius: 0;
    margin-right: 10px;
    padding: 4px 12px;
    color: var(--bs-primary);
}

.cv-tabs .nav-item button:hover, .cv-tabs .nav-item button.active {
    background-color: var(--bs-primary);
    color: #fff;
}

.cv-tabs .nav-item button:disabled {
    border: 1px solid var(--bs-secondary-bg) !important;
    background-color: var(--bs-secondary-bg);
    color: #ccc;
}
