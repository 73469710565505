.label {
    margin: 0;
    color: #495057;
    font-size: 13px;
}

.form-group {
    margin-bottom: 0.5rem!important;
}

.upload-button {
    border: 1px solid var(--bs-primary);
    color: var(--bs-primary);
    padding: 6px 12px;
    margin-bottom: 0;

}

.upload-button svg {
    fill: var(--bs-primary);
}

.upload-button:hover {
    background-color: var(--bs-primary);
    color: #fff;
}

.upload-button:hover svg {
    fill: #fff;
}
