.upload-cv span {
    display: inline;
}

.upload-cv div {
    display: inline;
}

.upload-cv input {
    display: none;
}

.upload-cv label {
    cursor: pointer;
}

.upload-cv svg {
    font-size: 20px;
    margin-top: -3px;
    color: black;
}
