.error > div {
  border-color: #fb6340;
  border-radius: 0.25rem;
}
.label {
  margin: 0;
  color: gray;
  font-size: 13px;
}
.form-group {
  margin-bottom: 0.5rem!important;
}

.clear {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  height: 16px;
  display: flex;
  right: 50px;
  color: #cccccc;
  transition: color 150ms;
}

.clear:hover {
  color: #999999;
}
.select-wrapper{
  position: relative;
}
