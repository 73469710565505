.download-button {
    float: right;
}
.download-button svg {
    font-size: 25px;
}
.cv-images {
    border: 1px solid black;
    max-height: none!important;
    height: 80vh;
    overflow: auto;
}
.cv-images img {
    width: 100%;
}

.upload-cv span {
    display: inline;
}

.upload-cv div {
    display: inline;
}

.upload-cv input {
    display: none;
}

.upload-cv label {
    cursor: pointer;
}

.upload-cv svg {
    font-size: 20px;
    margin-top: -3px;
    color: black;
}

.upload-cv .remove-button svg {
    color: #dc3545;
    font-size: 16px;
}

.upload-cv .remove-button:hover svg {
    color: #ffffff;
}
