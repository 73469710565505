.footer {
  width: 100%;
  margin: 0 0 0 0 !important;
  padding: 25px 0;
  border-top: 1px solid rgb(122 137 148 / 13%);
}
.logo {
  width: 63px;
  height: 35px;
}
