.login-box {
  display: flex;
  align-items: center;
  flex: 1;
}

.login {
  flex: 1;
  display: flex;
  gap: 20px;
  max-width: 1200px;
  margin: auto;
}
@media (max-width: 768px) {
  .login {
    flex-direction: column;
  }
}
.login-item {
  flex: 1;
  padding: 20px;
  min-height: 100%;
  background: #ffffffe6;
}
