@font-face {
    font-family: Nunito;
    src: url('./fonts/Nunito-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: Nunito-SemiBold;
    src: url('./fonts/Nunito-SemiBold.ttf');
    font-display: swap;
}

@font-face {
    font-family: Nunito-Bold;
    src: url('./fonts/Nunito-Bold.ttf');
    font-display: swap;
}

body {
    font-family: Nunito!important;
}

.nav-tabs button {
    border: none!important;
    color: #353535;
}

.nav-tabs button.active{
    z-index: 1;
    box-shadow: 0 9px 3px #fff, 0 -3px 7px #e9e9e9;
}

.nav-tabs {
    border-bottom: none!important;
}

.headline {
    margin-bottom: 15px;
    width: 100%;
    position: relative;
    text-transform: uppercase;
    font-family: Nunito-Bold;
}
.headline:after {
    content: '';
    display: block;
    border-bottom: 1px solid grey;
    margin-top: 7px;
}

.cursor-pointer {
    cursor: pointer;
}

.btn.btn-primary, .btn.btn-outline-primary,
.btn.btn-secondary, .btn.btn-outline-secondary,
.btn.btn-danger, .btn.btn-outline-danger {
    border-radius: 0;
}

.font-bold {
    font-family: Nunito-Bold, sans-serif;
}

.icon-link {
    color: #808080;
}
