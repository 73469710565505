.header {
    background-color: #ffffff;
    height: 60px;
    width: 100%;
    padding: 0 20px 0 0;
    z-index: 1001;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0!important;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}
.logo-wrapper{
    padding: 0 15px 0 20px;
}
.logo {
    width: 63px;
    height: 35px;
}
