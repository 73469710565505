.label {
  margin: 0;
  color: gray;
  font-size: 13px;
}
.input{
  border-radius: 0!important;
}

.input:focus{
  border-color: #ced4da!important;
  box-shadow: none!important;
}
.form-group {
  margin-bottom: 0.5rem!important;
}
.input-wrapper{
  position: relative;
}
.clear {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  height: 16px;
  display: flex;
  right: 10px;
  color: #cccccc;
  transition: color 150ms;
}

.clear:hover {
  color: #999999;
}

.icon-right{
  padding-right: 40px!important;
}
