.app-wrapper {
  position: relative;
  min-height: 85vh;
  background: #f8f8f8;
  padding-top: 60px;
}

.login-wrapper {
  display: flex;
  background-size: cover;
  background-image: url('/public/assets/login-bg.jpg');
}

.app-wrapper-content {
  padding-left: 200px;
}

.spinner-grow.preloader {
  --bs-spinner-width: 6rem;
  --bs-spinner-height: 6rem;
}
